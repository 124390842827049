import React, { useState } from "react";
import { useQuery } from "react-query";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import EmployeeApi from "../../services/APIs/Employee.Api";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { Cancel } from "@mui/icons-material";
// import EmployeeExitForm from "../EmployeeList/components/EmployeeExitForm";
import { green, red } from "@mui/material/colors";

import { EmployeeExitFormDialog } from "../EmployeeList/components";
import { ConfirmDialog } from "../../components";
import { toast } from "react-toastify";

const RemovalEmployee = () => {
  const [openUpdate, setOpenUpdate] = useState(false);
  const [removalID, setRemovalID] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { data, refetch: removalEmpRefetch } = useQuery(
    "GET_REMOVAL_EMPLOYEES",
    () => EmployeeApi.getRemovalEmployees()
  );

  const handleUpdate = (id) => {
    setOpenUpdate(true);
    setRemovalID(id);
  };

  const handleConfirmOpne = (id) => {
    setRemovalID(id);
    setConfirmOpen(true);
  };

  // Handle cancel confirm ..
  const handleCancelRemoval = async () => {
    const response = await EmployeeApi.cancelEmployeeRemoval(removalID);
    console.log("removal response ===", response);
    if (response.success) {
      toast.success(response.message);
      setConfirmOpen(false);
      removalEmpRefetch();
    } else {
      toast.error(response.message);
    }
  };
  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "fullName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },

    {
      name: "userName",
      label: "Submitted by",
    },

    {
      name: "createdDate",
      label: "Created date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "dateOfDeparture",
      label: "Removal date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "completed",
      label: "Completed",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const isCompleted = value === 1;
          const color = isCompleted ? green[500] : red[500];

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <FiberManualRecord
                sx={{
                  color: color,
                  fontSize: "10px",
                  marginRight: "5px",
                }}
              />
              {isCompleted ? "YES" : "NO"}
            </div>
          );
        },
      },
    },

    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          const dateOfDeparture = moment(tableMeta.rowData[4]).format(
            "MM-DD-YYYY"
          );
          const completed = tableMeta.rowData[5];
          const oneDayBeforeDeparture = moment(dateOfDeparture, "MM-DD-YYYY")
            .subtract(1, "days")
            .format("MM-DD-YYYY");

          return (
            <>
              {/* <IconButton
                onClick={() => {
                  handleUpdate(id);
                }}
              >
                <EditIcon />
              </IconButton> */}
              {/* Cancel removal */}
              {moment().isBefore(oneDayBeforeDeparture) && completed === 0 ? (
                <>
                  <Tooltip title="Cancel">
                    <IconButton onClick={() => handleConfirmOpne(id)}>
                      <Cancel sx={{ color: "#d50000" }} />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </>
          );
        },
      },
    },
  ];

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    // responsive,
    selectableRows: "none",

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  console.log("Data", data);
  return (
    <Grid>
      {/* Removal employee table */}
      <MUIDataTable
        title={"Removal Employee list"}
        data={data}
        columns={columns}
        options={options}
      />

      {/* Update removal employee dialog */}
      <EmployeeExitFormDialog
        open={openUpdate}
        setOpen={setOpenUpdate}
        removalID={removalID}
        setRemovalID={setRemovalID}
      />
      {/* Cancel employee remova dialog */}
      <ConfirmDialog
        title="Cancel Employee Removal ?"
        dialogContext="Are you sure to cancel employee removal"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleCancelRemoval}
      />
    </Grid>
  );
};

export default RemovalEmployee;
