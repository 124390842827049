import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IMaskInput } from "react-imask";
import {
  useParams,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { isNull } from "lodash";
import { useQuery } from "react-query";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Check, Close } from "@mui/icons-material";

import LocalStorage from "../../managers/LocalStorage";

import EmployeeApi from "../../services/APIs/Employee.Api";
import { employeeFormValidationSchema } from "../../utils/validations";
import { canadaProvince, americaStates } from "../../constants/AppConstants";
import { addBusinessDays, differenceInCalendarDays } from "date-fns";

// MUI X-Date-Pickers
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import DealerShipApi from "../../services/APIs/DealerShip.Api";
import AuthApi from "../../services/APIs/Auth.Api";
import moment from "moment";

const addBusinessDaysToDate = (date, businessDays) => {
  // bit of type checking, and making sure not to mutate inputs ::
  const momentDate = date instanceof moment ? date.clone() : moment(date);

  if (!Number.isSafeInteger(businessDays) || businessDays <= 0) {
    // handle these situations as appropriate for your program; here I'm just returning the moment instance ::
    return momentDate;
  } else {
    // for each full set of five business days, we know we want to add 7 calendar days ::
    const calendarDaysToAdd = Math.floor(businessDays / 5) * 7;
    momentDate.add(calendarDaysToAdd, "days");

    // ...and we calculate the additional business days that didn't fit neatly into groups of five ::
    const remainingDays = businessDays % 5;

    // if the date is currently on a weekend, we need to adjust it back to the most recent Friday ::
    const dayOfWeekNumber = momentDate.day();
    if (dayOfWeekNumber === 6) {
      // Saturday -- subtract one day ::
      momentDate.subtract(1, "days");
    } else if (dayOfWeekNumber === 0) {
      // Sunday -- subtract two days ::
      momentDate.subtract(2, "days");
    }

    // now we need to deal with any of the remaining days calculated above ::
    if (momentDate.day() + remainingDays > 5) {
      // this means that adding the remaining days has caused us to hit another weekend;
      // we must account for this by adding two extra calendar days ::
      return momentDate.add(remainingDays + 2, "days");
    } else {
      // we can just add the remaining days ::
      return momentDate.add(remainingDays, "days");
    }
  }
};

const initialValues = {
  firstName: "",
  middleInitialName: "",
  lastName: "",
  knowsAs: "",
  pronouns: "",
  dealership: "",
  startDate: null,
  department: "",
  position: "",
  gender: "",
  phone: "",
  sin: "",
  address: "",
  city: "",
  postalCode: "",
  email: "",
  birthDay: null,
  shareBirthDay: "",
  createPOs: "0",
  keyValut: "0",
  copyFromEmployee: [],
  additionalNotes: "",
  referredBy: [],
  reportingManager: [],
  draft: 0,
  employeeType: "Employee",
  // For america dealership .
  ssn: "",
  zipCode: "",
  provinceState: "",
  dealershipCountry: "",
  employeeNumber: "",
  autoGenerateEmployeeNumber: "yes",
  phoneExtension: "",
};

const EmployeeForm = () => {
  const { id } = useParams();
  const [openWarning, setOpenWarning] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const previousTabValue = location.state?.currentTabValue;

  const match = useMatch(`update/${id}`);
  const user = LocalStorage.getUser();
  const [sinIsNULL, setSinIsNULL] = useState(true);
  const [dealerships, setDealerShips] = useState([]);
  const [departments, setDepartMents] = useState([]);
  const [employeeForDD, setEmployeeForDD] = useState([]);
  const [managersForDD, setManagersForDD] = useState([]);

  const [singleEmployee, setSingleEmployee] = useState([]);
  // Other gender click  ..
  const [otherGenderClick, setOtherGenderClick] = useState(false);

  // Get Dealerships Methods  ...
  const getDealerships = async () => {
    if (user?.role === "Admin") {
      const response = await DealerShipApi.getDealersShips();
      setDealerShips(response);
    } else {
      const response = await DealerShipApi.getUserDealerships(user?.id);
      setDealerShips(response[0]?.dealerships);
    }
  };

  // Get Departments Mehtod   ..
  const getDepartements = async () => {
    if (user?.role === "Manager") {
      const response = await DealerShipApi.getUserDepartmetns(user?.id);
      setDepartMents(response[0]?.departments);
    } else {
      const response = await DealerShipApi.getDepartements();
      setDepartMents(response);
    }
  };

  // Get employee for copy from autocomplete ..
  const getEmployeesForDD = async () => {
    const response = await EmployeeApi.getEmployeeForDD();
    setEmployeeForDD(response);
  };

  // setting first department as slected in autocomplete ..
  const findManagerObjd = (managerName) => {
    if (managersForDD) {
      return managersForDD.find((dt) => dt?.name === managerName);
    }
  };
  const isManager = findManagerObjd(user?.name);
  useEffect(() => {
    if (isManager && isCreatePage) {
      formik.setFieldValue("reportingManager", [isManager]);
    }
  }, [isManager]);

  // setting first department as slected in autocomplete ..
  useEffect(() => {
    if (user?.role === "Manager" && departments.length > 0) {
      formik.setFieldValue("department", departments[0]?.name);
    }
  }, [departments]);

  const getManagersFromAD = async () => {
    try {
      const [response, error] = await EmployeeApi.getManagersFromAD();
      console.log("managers from AD");
      console.log(response);
      response.forEach((user) => {
        user.name = user.displayName;
        user.email = user.userPrincipalName;
      });
      setManagersForDD(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDealerships();
    getDepartements();
    getEmployeesForDD();
    getManagersFromAD();
  }, []);

  // Formik ..
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: employeeFormValidationSchema,

    onSubmit: (values) => {
      console.log("values", values);
      handleSubmit(values);
    },
  });

  // Get Single Employee By ID...
  useEffect(() => {
    if (id !== undefined) {
      const getEmployeeByID = async (id) => {
        const response = await EmployeeApi.getEmployeeByID(id);
        setSingleEmployee(response);
      };
      getEmployeeByID(id);
    }
  }, [id]);

  const isCreatePage = location.pathname === "/create";
  const isDraft = singleEmployee[0]?.draft === 1;

  const handleReportingManagerChange = (event, values) => {
    formik.setFieldValue("reportingManager", values);
  };

  function getCountryByName(nameToFind, valArray) {
    for (const item of valArray) {
      if (item.name === nameToFind) {
        return item.countryCode;
      }
    }
    return null;
  }

  let dealershipCountry = getCountryByName(
    formik.values.dealership,
    dealerships
  );

  // Set Formik if singleEmployee on update employee  ...
  useEffect(() => {
    if (!isNull(match)) {
      var {
        dealership,
        knowsAs,
        firstName,
        middleInitialName,
        lastName,
        startDate,
        department,
        position,
        gender,
        phone,
        sin,
        address,
        city,
        postalCode,
        email,
        birthDay,
        shareBirthDay,
        createPOs,
        keyValut,
        copyFromEmployee,
        additionalNotes,
        referredBy,
        reportingManager,
        employeeType,
        pronouns,
        provinceState,
        dealershipCountry,
        employeeNumber,
        phoneExtension,
      } = singleEmployee[0] ?? {};

      // Start Date Value
      const dateValue = startDate && dayjs(startDate);
      // Birthday Valued
      const birthDayValue = startDate && dayjs(birthDay);

      // set other Gender click on update employee ..
      setOtherGenderClick(
        gender !== "male" && gender !== "female" && gender !== ""
      );
      setSinIsNULL(sin === null);
      console.log(sinIsNULL, "sinIsNULL");
      let autoGenerateEmployeeNumber = "yes";
      if (employeeNumber) {
        autoGenerateEmployeeNumber = "no";
      }

      console.log("dealershipCountry", dealershipCountry);
      if (!dealershipCountry) {
        dealershipCountry = getCountryByName(dealership, dealerships);
        console.log("updated dealershipCountry", dealershipCountry);
      }

      formik.setValues({
        dealership,
        firstName,
        middleInitialName,
        lastName,
        knowsAs,
        startDate: dateValue,
        department,
        position,
        gender,
        phone,
        sin,
        address,
        city,
        postalCode,
        email,
        birthDay: birthDayValue,
        shareBirthDay,
        createPOs,
        keyValut,
        copyFromEmployee,
        additionalNotes,
        referredBy,
        reportingManager,
        employeeType,
        pronouns,
        // For america dealership ..
        ssn: sin || "",
        zipCode: postalCode,
        provinceState,
        dealershipCountry,
        employeeNumber,
        phoneExtension,
        autoGenerateEmployeeNumber,
      });
    }
  }, [singleEmployee]);

  // Handle Submit
  const handleSubmit = async (body) => {
    try {
      console.log("formik.values", formik.values);
      if (location.pathname === "/create") {
        const response = await EmployeeApi.createEmloyee(body);
        if (response.success) {
          toast.success(response.message);
          formik.resetForm();
          navigate("/employees", { state: { previousTab: previousTabValue } });
        } else {
          toast.error(response.message);
        }
      } else {
        console.log("body", body);
        // If isDraft True Call update and save draft value to 0 .. // On Update Draft Page ..
        if (isDraft === true) {
          console.log("isDraft === true");
          body = { ...formik.values, draft: 0 };
          const response = await EmployeeApi.updateDraft(body, id);
          if (response.success) {
            toast.success("Emplyee added successfully");
            formik.resetForm();
            navigate(-1);
          } else {
            toast.error(response.message);
          }
        } else {
          console.log("isDraft === false");
          // Otherwise call update the employee if isDraft not true

          const { dealershipCountry } = formik.values;

          // if (dealershipCountry === "CA") {
          //   formik.values.ssn = "";
          //   formik.values.zipCode = "";
          // } else {
          //   formik.values.sin = "";
          //   formik.values.postalCode = "";
          // }

          // Create the body object
          const body = { ...formik.values, draft: 0 };

          const response = await EmployeeApi.updateEmployee(body, id);

          if (response.success) {
            toast.success(response.message);
            formik.resetForm();
            navigate("/employees", {
              state: { previousTab: previousTabValue },
            });
          } else {
            toast.error(response.message);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // To Save Draft 1 on create employee api ....
  const handleSubmitDraft = async () => {
    formik.setTouched({
      firstName: true,
    });

    // Check if the fullName field is empty
    if (!formik.values.firstName) {
      return;
    } else {
      const body = { ...formik.values, draft: 1 };

      const response = await EmployeeApi.createDraft(body);
      console.log("response on create draft  ====>", response);
      if (response.success) {
        toast.success(response.message);
        formik.resetForm();
        navigate("/draft");
      } else {
        toast.error(response.message);
      }
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    navigate("/employees", { state: { previousTab: previousTabValue } });
  };

  // Save For Later on Update Draft  ....
  const saveForLaterUpdateDraft = async () => {
    const { dealershipCountry } = formik.values;

    if (dealershipCountry === "CA") {
      formik.values.ssn = "";
      formik.values.zipCode = "";
    } else {
      formik.values.sin = "";
      formik.values.postalCode = "";
    }

    // Create the body object
    const body = { ...formik.values, draft: 1 };
    const response = await EmployeeApi.updateDraft(body, id);
    if (response.success) {
      toast.success(response.message);
      formik.resetForm();
      navigate("/draft");
    } else {
      toast.error(response.message);
    }
  };

  const handleGenderChange = (event) => {
    const selectedValue = event.target.value;
    console.log("gender =", selectedValue);

    if (selectedValue === "other") {
      setOtherGenderClick(true);
      formik.setFieldValue("gender", "");
    } else {
      setOtherGenderClick(false);
      formik.setFieldValue("gender", selectedValue);
    }
  };

  const sinValLength = 11;

  // Check if sin exists and its length matches sinValLength
  const sinExists =
    !!formik.values?.sin && formik.values?.sin.length === sinValLength;

  // Check if ssn exists and its length matches sinValLength
  const ssnExists =
    !!formik.values?.ssn && formik.values?.ssn.length === sinValLength;

  // // Check sin exists or not  ..
  const {
    data: sinResult,
    isLoading,
    isFetched,
  } = useQuery(
    [
      "CHECK_SIN",
      dealershipCountry === "CA" ? formik.values?.sin : formik.values?.ssn,
    ],
    () => {
      if (isCreatePage) {
        return new Promise((resolve) => {
          // setTimeout(() => {
          resolve(
            EmployeeApi.checkSinExists({
              sin:
                dealershipCountry === "CA"
                  ? formik.values?.sin
                  : formik.values?.ssn,
              dealership: formik.values.dealership,
              country: formik.values.dealershipCountry,
            })
          );
          // }, 2000);
        });
      }
      //   else if (sinIsNULL) {
      //     return new Promise((resolve) => {
      //       setTimeout(() => {
      //         resolve(
      //           EmployeeApi.checkSinOnUpdateEmployee(
      //             {
      //               sin:
      //                 dealershipCountry === "CA"
      //                   ? formik.values?.sin
      //                   : formik.values?.ssn,
      //               dealership: formik.values.dealership,
      //               country: formik.values.dealershipCountry,
      //             },
      //             id
      //           )
      //         );
      //       }, 1000);
      //     });
      //   }
    },
    {
      enabled: dealershipCountry === "CA" ? sinExists : ssnExists,
      keepPreviousData: false,
    }
  );
  console.log("formik errors", formik.errors);
  console.log("formik values", formik.values);

  const checkFor2BusinessDays = async () => {
    let result = await formik.validateForm();
    if (Object.keys(result).length > 0) {
      return formik.handleSubmit();
    }
    const startDate = new Date(formik.values.startDate);
    const twoBusinessDaysFromNow = addBusinessDaysToDate(new Date(), 2);
    console.log(
      "twoBusinessDaysFromNow, startDate",
      twoBusinessDaysFromNow,
      startDate
    );
    console.log(
      "differenceInCalendarDays(twoBusinessDaysFromNow.toDate(), startDate)",
      differenceInCalendarDays(twoBusinessDaysFromNow.toDate(), startDate)
    );
    const isWithinTwoBusinessDays =
      differenceInCalendarDays(twoBusinessDaysFromNow.toDate(), startDate) > 0;
    console.log("isWithinTwoBusinessDays", isWithinTwoBusinessDays);
    if (isWithinTwoBusinessDays) {
      setOpenWarning(true);
    } else {
      formik.handleSubmit();
    }
  };

  return (
    // Main Container
    <Grid>
      <Button
        variant="contained"
        startIcon={<ArrowBack />}
        onClick={() =>
          isDraft
            ? navigate("/draft", { state: { previousTab: previousTabValue } })
            : navigate("/employees", {
                state: { previousTab: previousTabValue },
              })
        }
      >
        Back
      </Button>
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{ color: "grey", fontWeight: "bold", fontSize: 20 }}
            >
              {isCreatePage
                ? "Create New Employee"
                : isDraft
                ? "Update Draft"
                : "Update Employee"}
            </Typography>
          </Grid>
          <CardContent>
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                if (location.pathname === "/create") checkFor2BusinessDays();
                else formik.handleSubmit(e);
              }}
            >
              {" "}
              <Grid container spacing={3}>
                {/* Employee type radios */}
                <Grid item xs={12} md={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Employee Type</FormLabel>
                    <RadioGroup
                      row
                      aria-label="employee-type"
                      name="employeeType"
                      value={formik.values.employeeType ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <FormControlLabel
                        value="Employee"
                        control={<Radio />}
                        label="Employee"
                      />
                      <FormControlLabel
                        value="Manager"
                        control={<Radio />}
                        label="Manager"
                      />
                      <FormControlLabel
                        value="GM"
                        control={<Radio />}
                        label="GM"
                      />
                      <FormControlLabel
                        value="HR Director"
                        control={<Radio />}
                        label="HR Director"
                      />
                      <FormControlLabel
                        value="HR"
                        control={<Radio />}
                        label="HR"
                      />
                      <FormControlLabel
                        value="Executive"
                        control={<Radio />}
                        label="Executive"
                      />
                    </RadioGroup>
                    {formik.touched.employeeType &&
                      formik.errors.employeeType && (
                        <FormHelperText error>
                          {formik.errors.employeeType}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={dealerships || []}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      console.log("New value  ===>", newValue);
                      formik.setFieldValue("dealership", newValue?.name || "");
                      formik.setFieldValue(
                        "dealershipCountry",
                        newValue?.countryCode || ""
                      );
                    }}
                    value={
                      dealerships?.find(
                        (option) => option.name === formik.values.dealership
                      ) || null
                    }
                    // Disable on update
                    readOnly={location.pathname !== "/create"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="dealership"
                        size="small"
                        label="Dealership"
                      />
                    )}
                  />
                </Grid>

                {formik.values.dealership && (
                  <>
                    {(location.pathname === "/create" || isDraft) && (
                      <Grid item md={6} xs={12}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Employee Number Method?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="autoGenerateEmployeeNumber"
                            name="autoGenerateEmployeeNumber"
                            value={formik.values.autoGenerateEmployeeNumber}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Automatically Generate"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Enter Manually"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                    {(location.pathname === "/create" || isDraft) &&
                      formik.values.autoGenerateEmployeeNumber === "no" && (
                        <Grid item md={6} xs={12}>
                          <TextField
                            name="employeeNumber"
                            label="Employee Number"
                            type="text"
                            value={formik.values.employeeNumber}
                            onChange={formik.handleChange}
                            fullWidth
                            size="small"
                            error={
                              formik.touched.employeeNumber &&
                              Boolean(formik.errors.employeeNumber)
                            }
                            variant="outlined"
                            helperText={
                              formik.touched.employeeNumber &&
                              formik.errors.employeeNumber
                            }
                          />
                        </Grid>
                      )}
                    {location.pathname !== "/create" && !isDraft && (
                      <Grid item xs={12} md={6}></Grid>
                    )}
                    {location.pathname !== "/create" && !isDraft && (
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="employeeNumber"
                          label="Employee Number"
                          type="text"
                          value={formik.values.employeeNumber}
                          onChange={formik.handleChange}
                          fullWidth
                          size="small"
                          error={
                            formik.touched.employeeNumber &&
                            Boolean(formik.errors.employeeNumber)
                          }
                          variant="outlined"
                          helperText={
                            formik.touched.employeeNumber &&
                            formik.errors.employeeNumber
                          }
                        />
                      </Grid>
                    )}
                    {formik.values.autoGenerateEmployeeNumber === "yes" && (
                      <Grid item xs={12} md={6}></Grid>
                    )}
                    {/* SIN Text Field  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        name={dealershipCountry === "CA" ? "sin" : "ssn"}
                        label={dealershipCountry === "USA" ? "SSN" : "SIN"}
                        type="text"
                        value={
                          user.role === "Admin" ||
                          location.pathname === "/create"
                            ? dealershipCountry === "CA"
                              ? formik.values.sin
                              : formik.values.ssn
                            : "**********"
                        }
                        onChange={(e) => {
                          if (
                            user?.role === "Admin" ||
                            location.pathname === "/create"
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        fullWidth
                        size="small"
                        InputProps={{
                          readOnly:
                            !(location.pathname === "/create") && !sinIsNULL,
                          // Spinner
                          endAdornment: (
                            <>
                              {(isLoading &&
                                (location.pathname === "/create" ||
                                  sinIsNULL)) ||
                              (isLoading && user.role === "Admin") ? (
                                <>
                                  <InputAdornment position="end">
                                    <CircularProgress
                                      size={20}
                                      color="inherit"
                                    />
                                  </InputAdornment>
                                </>
                              ) : null}

                              {isFetched && (
                                <>
                                  <InputAdornment position="end">
                                    {!isLoading &&
                                    !sinResult?.success &&
                                    (location.pathname === "/create" ||
                                      sinIsNULL) ? (
                                      <Check color="success" />
                                    ) : null}
                                  </InputAdornment>
                                </>
                              )}
                            </>
                          ),
                          ...(user?.role === "Admin" ||
                          location.pathname === "/create" ||
                          sinIsNULL
                            ? {
                                inputComponent: IMaskInput,
                                inputProps: {
                                  mask: "000 000 000",
                                },
                              }
                            : {}),
                        }}
                        error={
                          dealershipCountry === "CA"
                            ? formik.touched.sin && Boolean(formik.errors.sin)
                            : formik.touched.ssn && Boolean(formik.errors.ssn)
                        }
                        variant="outlined"
                        helperText={
                          dealershipCountry === "CA"
                            ? formik.touched.sin && formik.errors.sin
                            : formik.touched.ssn && formik.errors.ssn
                        }
                      />
                      {!isLoading && sinResult?.success && (
                        <Typography
                          sx={{ color: "#d32f2f", fontSize: 13, margin: 1 }}
                        >
                          {`${
                            formik.values.dealershipCountry === "CA"
                              ? "SIN"
                              : "SSN"
                          }  already exists`}
                        </Typography>
                      )}
                    </Grid>

                    {/* First Name Text Field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="firstName"
                        label="First Name"
                        type="text"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        variant="outlined"
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                    </Grid>
                    {/* Middle Initial Name Text Field */}
                    <Grid item md={2} xs={12}>
                      <TextField
                        name="middleInitialName"
                        label="Middle Initial"
                        type="text"
                        value={formik.values.middleInitialName}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    {/* Last Name Text Field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="lastName"
                        label="Last Name"
                        type="text"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    {/* Knows As Text Field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="knowsAs"
                        label="Known As"
                        type="text"
                        value={formik.values.knowsAs}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        variant="outlined"
                        helperText={
                          "Some people go by different name than their real name."
                        }
                      />
                    </Grid>
                    {/* Pronouns Text Field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="pronouns"
                        label="Pronouns"
                        type="text"
                        value={formik.values.pronouns}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    {/* Gender Radio Group */}
                    <Grid item md={6} xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="gender"
                          value={formik.values.gender ?? ""}
                          onChange={handleGenderChange}
                          onBlur={formik.handleBlur}
                        >
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Female"
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio checked={otherGenderClick} />}
                            label="Other"
                          />
                        </RadioGroup>
                      </FormControl>
                      {otherGenderClick && (
                        <>
                          <TextField
                            sx={{ mt: 1 }}
                            name="gender"
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                            label="Other Gender"
                            type="text"
                            fullWidth
                            size="small"
                            variant="outlined"
                          />
                        </>
                      )}
                    </Grid>
                    {/* MUI Start Date Picker */}
                    <Grid item md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="YYYY-MM-DD"
                          sx={{ width: "100%", height: "5%" }}
                          label="Start Date"
                          value={formik.values.startDate}
                          onChange={(date) =>
                            formik.setFieldValue("startDate", date)
                          }
                          onBlur={() =>
                            formik.setFieldTouched("startDate", true)
                          }
                          slotProps={{
                            textField: {
                              size: "small",
                              variant: "outlined",
                              error:
                                formik.touched.startDate &&
                                Boolean(formik.errors.startDate),
                              helperText:
                                formik.touched.startDate &&
                                formik.errors.startDate,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {/* Department Auto Complete */}
                    <Grid item md={6} xs={12}>
                      {location.pathname === "/create" || isDraft ? (
                        <Autocomplete
                          options={departments || []}
                          getOptionLabel={(option) => option?.name}
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              "department",
                              newValue?.name || ""
                            );
                          }}
                          value={
                            departments?.find(
                              (option) =>
                                option?.name === formik.values.department
                            ) || null
                          }
                          // Disable on update
                          readOnly={location.pathname !== "/create" && !isDraft}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="outlined"
                              name="department"
                              size="small"
                              label="Select Department"
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          value={formik.values.department}
                          fullWidth
                          variant="outlined"
                          name="department"
                          size="small"
                          label="Department"
                        />
                      )}
                    </Grid>
                    {/* Position Text Field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="position"
                        label="Position Title"
                        type="text"
                        value={formik.values.position}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        variant="outlined"
                        inputProps={{
                          readOnly: location.pathname !== "/create" && !isDraft,
                        }}
                      />
                    </Grid>

                    {/* Phone Text Field  */}
                    <Grid item md={4} xs={8}>
                      <TextField
                        name="phone"
                        label="Phone"
                        type="text"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        InputProps={{
                          inputComponent: IMaskInput,
                          inputProps: {
                            mask: "+1 (000) 000-0000",
                          },
                        }}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        variant="outlined"
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <TextField
                        name="phoneExtension"
                        label="Extention"
                        type="text"
                        value={formik.values.phoneExtension}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        error={
                          formik.touched.phoneExtension &&
                          Boolean(formik.errors.phoneExtension)
                        }
                        variant="outlined"
                        helperText={
                          formik.touched.phoneExtension &&
                          formik.errors.phoneExtension
                        }
                      />
                    </Grid>
                    {/* Address Text Field  */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="address"
                        label="Address"
                        type="text"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        variant="outlined"
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                      />
                    </Grid>
                    {/* City Text Field */}
                    <Grid item md={6} xs={3}>
                      <TextField
                        name="city"
                        label="City"
                        type="text"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    {/* Postal Code Text Field */}
                    <Grid item md={6} xs={3}>
                      <TextField
                        name={
                          dealershipCountry === "CA" ? "postalCode" : "zipCode"
                        }
                        label={
                          dealershipCountry === "USA"
                            ? "Zip Code"
                            : "Postal Code"
                        }
                        type="text"
                        value={
                          dealershipCountry === "CA"
                            ? formik.values.postalCode
                            : formik.values.zipCode
                        }
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        fullWidth
                        size="small"
                        InputProps={{
                          inputComponent: IMaskInput,
                          inputProps: {
                            mask:
                              dealershipCountry === "CA"
                                ? "a0a 0a0"
                                : "000 000",
                          },

                          formatChars: {
                            9: "[0-9]",
                            a: "[A-Z]",
                          },
                        }}
                        error={
                          dealershipCountry === "CA"
                            ? formik.touched.postalCode &&
                              Boolean(formik.errors.postalCode)
                            : formik.touched.zipCode &&
                              Boolean(formik.errors.zipCode)
                        }
                        variant="outlined"
                        helperText={
                          dealershipCountry === "CA"
                            ? formik.touched.postalCode &&
                              formik.errors.postalCode
                            : formik.touched.zipCode && formik.errors.zipCode
                        }
                      />
                    </Grid>
                    {/* Province auto complete */}
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        options={
                          dealershipCountry === "USA"
                            ? americaStates || []
                            : canadaProvince || []
                        }
                        getOptionLabel={(option) => option?.label}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            "provinceState",
                            newValue?.value || ""
                          );
                        }}
                        value={
                          dealershipCountry === "USA"
                            ? americaStates?.find(
                                (option) =>
                                  option.label === formik.values.provinceState
                              ) || null
                            : canadaProvince.find(
                                (option) =>
                                  option.label === formik.values.provinceState
                              ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            name="provinceState"
                            size="small"
                            label={
                              dealershipCountry === "USA" ? "State" : "Province"
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/* Email Text Field */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="email"
                        label="Personal Email"
                        type="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        variant="outlined"
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    {/* Copy For Employee auto complete */}
                    <Grid item md={6} xs={12} sx={{ mt: 1 }}>
                      <Autocomplete
                        options={employeeForDD || []}
                        getOptionLabel={(option) => option?.name || ""}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Box
                              sx={{
                                flexGrow: 1,
                                "& span": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? "#586069"
                                      : "#8b949e",
                                },
                              }}
                            >
                              {option?.name}
                              <br />
                              <span>{option?.position}</span>
                            </Box>
                          </li>
                        )}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            formik.setFieldValue(
                              "copyFromEmployee",
                              newValue || ""
                            );
                          } else {
                            formik.setFieldValue("copyFromEmployee", "");
                          }
                        }}
                        value={formik.values.copyFromEmployee || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            name="copyFromEmployee"
                            size="small"
                            variant="outlined"
                            label="Copy Permissions From Existing Employee"
                          />
                        )}
                      />
                    </Grid>
                    {/* MUI Birthday Date Picker */}
                    <Grid item md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="YYYY-MM-DD"
                          sx={{ width: "100%" }}
                          label="Birthday"
                          value={formik.values.birthDay}
                          onChange={(date) =>
                            formik.setFieldValue("birthDay", date)
                          }
                          slotProps={{
                            textField: {
                              size: "small",
                              variant: "outlined",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {/* Share Birthday Radio Group */}
                    <Grid item md={6} xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Share Birthday</FormLabel>
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="shareBirthDay"
                          value={formik.values.shareBirthDay ?? ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                        <small>
                          If yes, birthday will be shared on company blog
                        </small>
                      </FormControl>
                    </Grid>

                    {/* Referred By Text Field */}
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        options={employeeForDD || []}
                        getOptionLabel={(option) => option?.name || ""}
                        onChange={(event, newValue) => {
                          formik.setFieldValue("referredBy", newValue || "");
                        }}
                        value={formik.values.referredBy || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            name="referredBy"
                            size="small"
                            label="Referred By"
                          />
                        )}
                      />
                    </Grid>
                    {/* Reporting Manager  Text Field */}
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        multiple
                        // disabled={formik.values.role === "Admin"}
                        options={managersForDD || []}
                        getOptionLabel={(option) => option.displayName}
                        // getOptionSelected={(option, value) =>
                        //   option.ID === value.ID
                        // }
                        value={formik.values.reportingManager || []}
                        onChange={handleReportingManagerChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reporting Manager"
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="reportingManager"
                          />
                        )}
                      />
                    </Grid>
                    {/* Additional Text Field */}
                    <Grid item md={12} xs={12}>
                      <TextField
                        name="additionalNotes"
                        label="Additional Notes"
                        type="text"
                        multiline
                        rows={3}
                        value={formik.values.additionalNotes}
                        onChange={formik.handleChange}
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    {/* Buttons */}
                    {/* Buttons */}
                    <Grid
                      sx={{ margin: "15px" }}
                      container
                      spacing={1}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <Button
                          color="primary"
                          variant="text"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      {location.pathname === "/create" && (
                        <Grid item>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleSubmitDraft}
                          >
                            Save For Later
                          </Button>
                        </Grid>
                      )}

                      {/* Save For Later on Draft Update Form  */}
                      {isDraft && (
                        <>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="inherit"
                              onClick={saveForLaterUpdateDraft}
                            >
                              Save For Later
                            </Button>
                          </Grid>
                        </>
                      )}

                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {location.pathname === "/create"
                            ? "Submit"
                            : "Update"}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={openWarning}
        onClose={() => {
          setOpenWarning(false);
        }}
      >
        <DialogTitle>NOTE!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The employee start date you entered is{" "}
            {moment(formik.values.startDate).format("Do MMM YYYY")}. Please note
            that our policy mandates a minimum 2 business days notice for IT to
            process new users. Accordingly, the user accounts will be activated
            on{" "}
            <strong>
              {addBusinessDaysToDate(new Date(), 2).format("Do MMMM YYYY")}
            </strong>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpenWarning(false);
              formik.handleSubmit();
            }}
            color="error"
          >
            I Understand
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default EmployeeForm;
